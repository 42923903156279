import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'elmtr-checkout',
  templateUrl: './checkout.component.html',
  styleUrl: './checkout.component.scss'
})
export class CheckoutComponent implements OnInit {
  ngOnInit(): void {
    
    console.log('logged checkout component');
    
  }
}
