import { Component } from '@angular/core';

@Component({
  selector: 'elmtr-changelog',
  templateUrl: './changelog.component.html',
  styleUrl: './changelog.component.scss'
})
export class ChangelogComponent {

}
