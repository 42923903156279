import { Routes } from '@angular/router';

import { AuthenticatedComponent } from "../modules/elementar-common/layouts/authenticated/authenticated.component";


import { TestComponent } from "../modules/elementar-common/test/test.component";
import { PublicComponent } from '../modules/elementar-common/layouts/public/public.component';
import { ProductsComponent } from '../modules/fotons/products/products.component';
import { ProductDetailsComponent } from '../modules/fotons/product-details/product-details.component';
import { OrdersComponent } from "../modules/fotons/orders/orders.component";
import { ClientsComponent } from '../modules/fotons/clients/clients.component';
import { CheckoutComponent } from "../modules/fotons/checkout/checkout.component";

import { loggedGuard } from "../modules/elementar-common/guards/logged.guard";
import { ChangelogComponent } from '../modules/fotons/changelog/changelog.component';



export const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'auth'
  },
  {
    path: 'auth',
    component: AuthenticatedComponent,
    canActivate:[loggedGuard],
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'orders'
      },
      {
        path: 'test',
        component: TestComponent
      },
      {
        path: 'products/fotons',
        component: ProductDetailsComponent
      },
      {
        path: 'checkout',
        component: CheckoutComponent
      },
      // {
      //   path: 'current-orders',
      //   component: OrdersComponent
      // }
      {
        path: 'orders',
        component: OrdersComponent
      },
      {
        path: 'clients',
        component: ClientsComponent
      },
      {
        path: 'changelog',
        component: ChangelogComponent
      }
    ]
  },
  {
    path: 'public',
    component: PublicComponent,
    loadChildren: () => import('../modules/public/public.module').then(m => m.PublicModule)
  }
];
